/* eslint-disable no-unused-vars */
import { lazy } from 'react'

import {
  ADMIN_CONFIGURATION,
  ADMIN_DELETE,
  ADMIN_REPORT,
  DATA_GATHERING_TALENT,
  DATA_IMPORT,
  KPI_GRAPH_REPORTS,
  KPI_OVERVIEW,
  LABELS,
  MISSION_CONTROL,
  SETTINGS,
  TALENT,
  TALENT_PROFILE,
  WORK_OPPORTUNITY,
} from '../description/rolesNPermission.description'
import AdminSettings from '../presentation/admin/adminSettings/AdminSettings'
import UserManual from '../presentation/UserManual'
import { redirectTo } from '../utils/constant'
/**
 * Public route components
 */

const Login = lazy(() => import('../presentation/login'))
const VerifyLogin = lazy(() => import('../presentation/VerifyLogin'))
const PermissionDenied = lazy(() => import('../presentation/PermissionDenied'))
/**
 * Launchpad components
 */
const Talents = lazy(() => import('../presentation/talents/Talents'))
const WorkOpportunities = lazy(() =>
  import('../presentation/workOpportunities/WorkOpportunities'),
)

const TalentProfile = lazy(() =>
  import('../presentation/talentProfile/TalentProfile'),
)

const JobDetails = lazy(() => import('../presentation/jobDetails'))
const Dataset = lazy(() => import('../presentation/datasets'))

/**
 * Dashboard components
 */

const Labels = lazy(() => import('../presentation/labels/Labels'))

const KPIOverview = lazy(() => import('../presentation/kpis/kpiOverview/index'))
const KPIOvertimeReport = lazy(() =>
  import('../presentation/kpis/graphReports/index'),
)

/**
 * Mission control components
 */

const MissionControl = lazy(() => import('../presentation/missionControl'))

const AddMissionControl = lazy(() =>
  import('../presentation/missionControl/AddMissionControl'),
)

/**
 * General component
 */

const Settings = lazy(() => import('../presentation/Settings'))

/**
 * Not found component
 */

const NotFound = lazy(() => import('../presentation/NotFound'))

/**
 * Data Gathering components
 */

const DataGatheringTalent = lazy(() =>
  import('../presentation/dataGathering/DataGatheringTalent'),
)

/**
 * Admin components
 */

const DataReset = lazy(() => import('../presentation/admin'))
const EditConfigs = lazy(() =>
  import('../presentation/admin/editConfiguration'),
)
const Reports = lazy(() => import('../presentation/admin/reports'))

/**
 * Notification components
 */
const Notifications = lazy(() => import('../presentation/notifications'))

/**
 * Route description
 */

const allRoutes = [
  {
    path: '/data-import',
    component: Dataset,
    title: 'Data import',
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    metaContent: 'Data import',
    pagePermission: DATA_IMPORT,
    exact: true,
  },
  {
    path: '/talents',
    component: Talents,
    title: 'Talents',
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    metaContent: 'Talents',
    pagePermission: TALENT,
    exact: true,
  },
  {
    path: '/work-opportunities',
    component: WorkOpportunities,
    title: 'Work Opportunities',
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    metaContent: 'Work Opportunities',
    pagePermission: WORK_OPPORTUNITY,
    exact: true,
  },
  {
    path: '/talent-profile',
    component: TalentProfile,
    title: 'Talent Profile',
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    metaContent: 'Talent Profile',
    pagePermission: TALENT_PROFILE,
    exact: true,
  },
  {
    path: '/job-details',
    component: JobDetails,
    title: 'Job Details',
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    metaContent: 'Work Opportunities',
    pagePermission: WORK_OPPORTUNITY,
    exact: false,
  },
  {
    path: '/labels',
    component: Labels,
    title: 'Labels',
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    metaContent: 'Labels',
    pagePermission: LABELS,
    exact: true,
  },
]

const missionRoutes = [
  {
    path: '/mission-control',
    component: MissionControl,
    title: 'Mission Control',
    exact: true,
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    pagePermission: MISSION_CONTROL,
  },
  {
    path: '/mission-control/add-mission',
    component: AddMissionControl,
    title: 'Mission Control',
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    metaContent: 'Mission Control',
    pagePermission: MISSION_CONTROL,
    exact: true,
  },
]

const generalRoutes = [
  {
    path: '/settings',
    component: Settings,
    title: 'Settings',
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    metaContent: 'Settings',
    pagePermission: SETTINGS,
    exact: true,
  },
  {
    path: '/user-manual',
    component: UserManual,
    title: 'User Manual',
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    metaContent: 'User Manual',
    pagePermission: true,
    exact: true,
  },
  {
    path: '/notifications',
    component: Notifications,
    title: 'Notifications',
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    metaContent: 'Notifications',
    pagePermission: true,
    exact: true,
  },
]

const publicRoutes = [
  {
    path: ['/', redirectTo.login],
    component: Login,
    isAuth: true,
    title: 'Login',
    exact: true,
    redirectUrl: '/work-opportunities',
    metaContent: 'Login',
    pagePermission: true,
  },
  {
    path: '/openid-complete',
    component: VerifyLogin,
    isAuth: true,
    title: 'Verify',
    redirectUrl: '/work-opportunities',
    metaContent: 'Login',
    pagePermission: true,
  },
  {
    path: '/verification-failed',
    component: PermissionDenied,
    isAuth: true,
    title: 'Verification Failed',
    redirectUrl: '/verification-failed',
    metaContent: '',
    pagePermission: true,
  },
]

const dataGatheringRoutes = [
  {
    path: '/data-gathering/talent',
    component: DataGatheringTalent,
    title: 'Talent',
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    metaContent: 'Data Gathering / Talent',
    pagePermission: DATA_GATHERING_TALENT,
    exact: true,
  },
]

const adminRoutes = [
  {
    path: '/kpis/overview',
    component: KPIOverview,
    title: 'KPI Overview',
    exact: true,
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    pagePermission: KPI_OVERVIEW,
    onlyAdmin: false,
  },
  {
    path: '/kpis/graph-reports',
    component: KPIOvertimeReport,
    title: 'Graph Reports',
    exact: true,
    isUserLoggedIn: true,
    redirectUrl: redirectTo.login,
    pagePermission: KPI_GRAPH_REPORTS,
    onlyAdmin: false,
  },
  {
    path: '/admin/data-reset',
    isUserLoggedIn: true,
    component: DataReset,
    title: 'Admin - Data Reset',
    metaContent: 'Dashboard / Data Reset',
    pagePermission: ADMIN_DELETE,
    exact: true,
  },
  {
    path: '/admin/settings',
    isUserLoggedIn: true,
    component: AdminSettings,
    title: 'Admin Settings',
    metaContent: 'Admin / Settings',
    pagePermission: ADMIN_CONFIGURATION,
    exact: true,
  },
  {
    path: '/admin/edit-profile',
    isUserLoggedIn: true,
    component: EditConfigs,
    title: 'Admin - Edit Profile',
    metaContent: 'Dashboard / Edit Profile',
    pagePermission: ADMIN_CONFIGURATION,
    exact: true,
  },
  {
    path: '/admin/reports',
    isUserLoggedIn: true,
    component: Reports,
    title: 'Admin - Reports',
    metaContent: 'Admin / Reports',
    pagePermission: ADMIN_REPORT,
    exact: true,
  },
]

export const privateRoutes = [
  ...allRoutes,
  ...missionRoutes,
  ...generalRoutes,
  ...dataGatheringRoutes,
  ...adminRoutes,
]

const notFound = [
  {
    path: '*',
    component: NotFound,
    title: 'Not found',
    metaContent: 'Not found',
  },
]

const routes = [...publicRoutes, ...privateRoutes, ...notFound]
const prioRoutes = [
  {
    pagePermission: TALENT_PROFILE,
    redirectUrl: '/talent-profile',
  },
  { pagePermission: WORK_OPPORTUNITY, redirectUrl: '/work-opportunities' },
]
export const fallbackRoutes = [...prioRoutes, ...privateRoutes]

export default routes
